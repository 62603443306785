.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.block_auth {
  width: 328px;
  // height: 344px;
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 34px 32px;
}

.title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 26px;
}

.block_auth input {
  width: 100%;
  margin-bottom: 20px;
}
.error {
  width: 100%;
  // margin-bottom: 20px;
  margin-top: -10px;
  color: #f54a3f;
font-size: 12px;
}
