@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-VariableFont_slnt\,wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/static/Inter-Bold-slnt=0.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/static/Inter-ExtraBold-slnt=0.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #181609;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.react-datepicker__input-container input {
  width: 108px;
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 4px;
  height: 36px;
  margin-right: 10px;
}

.item_title {
  font-size: 12px;
  line-height: 16px;
  color: #828282;
  margin-bottom: 8px;
}

input {
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 4px;
  height: 44px;
  /* width: 524px; */
  padding-left: 10px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.pagination li a {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.pagination li a:focus,
.pagination li.active:focus {
  border: none !important;
  /* background-color: aqua;
  border: 1px solid red; */
}

.pagination li {
  list-style-type: none;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #fed500;
  width: 44px;
  height: 44px;
  font-weight: bold;
  font-size: 14px;
}

.pagination li.active {
  background: #fed500;
}

.pagination li.break-me {
  border: none;
}

.pagination li.next,
.pagination li.previous {
  display: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.block_basket {
  align-items: flex-start !important;
  justify-content: flex-start !important;
  position: relative;
}

.delete {
  background-image: url("../images/delete.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 14px;
  height: 18px;
  cursor: pointer;
  margin-right: 13px;
}
.delete:hover {
  opacity: 0.8;
  transition: all 0.2s;
}