:root {
    --yellow: #FED500;
    --dark-yellow: #F1CC0B;

    --gray-navbar: #726F5F;
    --footer-gray-title: #828282;
    --dark-text: #181609;

    --red-button: #F54A3F;
    --white: #fff;

    --placeholder-gray: #CDCDCD;
}